import React from 'react';

import Container from 'components/Container/Container';
import HeroText from 'components/HeroText/HeroText';
import Layout from 'components/Layout';
import Seo from 'components/Seo';

import { banner } from 'styles/pages/common.module.scss';

const NotFoundPage = () => (
  <Layout fullWidth>
    <HeroText title="404: NOT FOUND" containerClass={banner} />
    <Seo title="404: Not found" />
    <Container withPadding isContent>
      <h1>Πρόβλημα!</h1>
      <p>Ευτυχώς όχι μεγάλο αλλά δυστυχώς η σελίδα που ψάχνεις δεν βρέθηκε…</p>
    </Container>
  </Layout>
);

export default NotFoundPage;
